/**
 * Created by janmencl on 07/02/2019.
 */

function Tabs(tabs, currentTab) {
    this.tabs = tabs || [];
    this.currentTab = currentTab || "";

    this.nextTab = function () {
        if(_.indexOf(this.tabs, this.currentTab) >= 0 && this.tabs.length > _.indexOf(this.tabs, this.currentTab)){
            this.currentTab = this.tabs[_.indexOf(this.tabs, this.currentTab) + 1];
            this.renderTabs();
        }
    };

    this.prevTab = function () {
        if(_.indexOf(this.tabs, this.currentTab) > 0 ){
            this.currentTab = this.tabs[_.indexOf(this.tabs, this.currentTab) - 1];
            this.renderTabs();
        }
    };

    this.renderTabs = function () {
        this.hideAll();
        if(!$(this.currentTab).hasClass('tab-visible')){
            $(this.currentTab).removeClass('tab-disabled').addClass('tab-visible').css('opacity', '1');
        }
        else {
            $(this.currentTab).removeClass('tab-disabled').css('opacity', '1');
        }
    };
    this.hideAll = function () {
        this.tabs.forEach(function(element) {
            $(element).css('opacity', '0.3').addClass('tab-disabled');
        });
    };
}
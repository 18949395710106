
//years
var years = [];

for (var i = 5; i<=30; i++){
    years.push(i);
}

//fixes
var fixes = [
    0.0329,
    0.0309,
    0.0299,
    0.0299,
    0.0309
];

var Calculator = new Calculator(fixes, years);

//init tabs
tabs = ['.first-tab', '.second-tab', '.third-tab'];
TabManager = new Tabs(tabs, tabs[0]);

//number events
$(function () {

    $('document').ready(function () {
       Calculator.changeValues();
    });

    $('.next-tab').on('click', function (e) {
        e.preventDefault();
        TabManager.nextTab();
       /* $('html, body').animate({
            scrollTop: $(TabManager.currentTab).offset().top - 20
        }, 500);*/

        parent.postMessage({'scroll_top': $(TabManager.currentTab).offset().top - 20}, 'https://vhd.vfd.cz');

    });

    $('.prev-step').on('click', function (e) {
        e.preventDefault();
        TabManager.prevTab();
      /*  $('html, body').animate({
            scrollTop: $(TabManager.currentTab).offset().top - 20
        }, 500);*/
        parent.postMessage({'scroll_top': $(TabManager.currentTab).offset().top - 20}, 'https://vhd.vfd.cz');
    });

    $('.first-tab .number-input').each(function () {
        $(this).append("<div class='number-controls'><div class='number-up number'>+</div> <div class='number-down number'>-</div></div>");
    });

    $('.number-up').on('click', function (e) {
        Calculator.changeValues();
        e.preventDefault();
        var input = $(this).parent().parent().find('.hidden-number'); //get the hidden input to get value later
        var parrent = $(this).parent().parent().find('.input'); // get parrent

        if(input.val() < Number($(parrent).attr('data-max'))-Number($(parrent).attr('data-step'))) {
            input.val(Number(input.val()) + Number($(parrent).attr('data-step')));
        }
        else{
            input.val(Number($(parrent).attr('data-max')));
        }
        $(this).parent().parent().find('.input').val(formatCurrency(input.val()));

        if(Calculator.changeValues() === false){
            $(this).addClass('border-red').addClass('animated').addClass('heartBeat');
            var that = this;
            setTimeout(function () {
                $(that).removeClass('border-red');
                $(that).removeClass('animated').removeClass('heartBeat');
            }, 1000);
            $("body").overhang({
                type: "error",
                html: true,
                closeConfirm: true,
                message: "Maximální výše hypotéky může být jen 90 % ceny nemovitosti. <br>Pokud odhadce stanoví hodnotu bytu ve výši 3 000 000 Kč,<br> maximální výše hypotéky bude 2 700 000 Kč.",
            });
            parent.postMessage({'task': 'scroll_top'}, parent_origin);
        }
    });

    $('.number-down').on('click', function (e) {
        Calculator.changeValues();
        e.preventDefault();
        var input = $(this).parent().parent().find('.hidden-number');
        var parrent = $(this).parent().parent().find('.input');
        if(input.val() >= Number($(parrent).attr('data-step'))) {
            input.val(Number(input.val()) - Number($(parrent).attr('data-step')));
        }
        else{
            input.val(0);
        }

        $(this).parent().parent().find('.input').val(formatCurrency(input.val()));
        Calculator.changeValues();

    });

    $('.first-tab .number-input .input').on('change', function (e) {

        e.preventDefault();
        var re = /([0-9])+/;
        var regResp = re.exec($(this).val());
        console.log(regResp);
        var input = $(this).parent().parent().find('.hidden-number');

        if(regResp !== null) {
            if(regResp[0] >=100000) {
                input.val(regResp[0]);
                $(this).parent().parent().find('.input').val(formatCurrency(input.val()));
            }
            else{
                input.val(100000);
                $(this).parent().parent().find('.input').val(formatCurrency(input.val()));
            }

        }
        Calculator.changeValues();

    });

});

//selects
var years_select = [];

for (var j = 5; j<=30; j++){
    if(j === 15){
        years_select.push({
            id: j-5,
            text: j + ' let',
            selected: true
        });
    }
    else {
        years_select.push({
            id: j - 5,
            text: j + ' let'
        });
    }
}

var fixes_select = [
    {
        id: 0,
        text: '1 rok'
    },
    {
        id: 1,
        text: '3 roky'
    },
    {
        id: 2,
        text: '5 let',
        "selected": true
    },
    {
        id: 3,
        text: '7 let'

    },
    {
        id: 4,
        text: '10 let'
    }
];

//select events
$(function () {
    $(".select-length").select2({
        data: years_select,
        minimumResultsForSearch: -1
    });

    $(".select-fix").select2({
        data: fixes_select,
        minimumResultsForSearch: -1
    });


    $(".select-length").on('change', function () {
        Calculator.changeValues();
    });

    $(".select-fix").on('change', function () {
        Calculator.changeValues();
    });

});


//lists
var incomes = [
    'Z pronájem',
    'Jiné příjmy'
];
var expenses = [
    'Splátky',
    'Spoření',
    'Výživné',
    'Ostatní'
];

var incomes_select = [
    {
        id: 0,
        text: 'Z pronájem'
    },
    {
        id: 1,
        text: 'Jiné příjmy'
    }
];

$(function () {

    $('.income .input').each(function (e) {
        var re = /([0-9])+/;
        var regResp = re.exec($(this).val().replace(/\s/g, ""));
        var input = $(this).parent().parent().find('.hidden-number');
        if(regResp !== null) {
            $(this).val(formatCurrency(regResp[0]));
            $(input).val(regResp[0]);
        }
    });

    $('.income .input').on('change', function (e) {
        var re = /([0-9])+/;
        var regResp = re.exec($(this).val().replace(/\s/g, ""));
        var input = $(this).parent().parent().find('.hidden-number');
        if(regResp !== null) {
            $(this).val(formatCurrency(regResp[0]));
            $(input).val(regResp[0]);
        }
    });

    $('.expense .input').each(function (e) {
        var re = /([0-9])+/;
        var regResp = re.exec($(this).val().replace(/\s/g, ""));
        var input = $(this).parent().parent().find('.hidden-number');
        if(regResp !== null) {
            $(this).val(formatCurrency(regResp[0]));
            $(input).val(regResp[0]);
        }
    });

    $('.expense .input').on('change', function (e) {
        var re = /([0-9])+/;
        var regResp = re.exec($(this).val().replace(/\s/g, ""));
        var input = $(this).parent().parent().find('.hidden-number');
        if(regResp !== null) {
            $(this).val(formatCurrency(regResp[0]));
            $(input).val(regResp[0]);
        }
    });

    $('.second-user').on('change',function () {
       if($(this).val() === 'on'){
           $('.second-user-wrapper').css('display', 'block');
           $(this).attr('disabled', 'disabled');
       }
       else{
           $('.second-user-wrapper').css('display', 'none');
       }
    });

    $('.calculate').on('click', function () {
       if(Calculator.calculate()){
           TabManager.nextTab();
           $('html, body').animate({
               scrollTop: $(TabManager.currentTab).offset().top - 20
           }, 500);

           $('.max-loan').html(formatCurrency(String((Calculator.income - Calculator.expense)*9*12)));
       }

    });

    $('.form-send').on('click', function (e) {
        e.preventDefault();

        $("body").overhang({
            type: "success",
            html: true,
            message: "Formulář byl úspěšně odeslán, brzy Vás budeme kontaktovat.",
        });

        var settings = {
            "async": true,
            "crossDomain": true,
            "url": "https://portal.vfd.cz/api/v1/klienti",
            "method": "POST",
            "headers": {
                "accept": "application/json",
                "x-api-key": "efe8018e-b96a-4640-98bf-6c697253c7ad",
                "content-type": "application/json",
                "cache-control": "no-cache",
                "postman-token": "dc087170-4d3c-c712-dec5-576db824f0bf"
            },
            "processData": false,
            "data": "{\n\"jmeno\": \"Janík\",\n\"prijmeni\": \"Menclík\",\n\"telefon\": \"+222 775 565 371\",\n\"email\": \"mail@mail.com\",\n\"rodne_cislo\": \"970225/0492\",\n\"zdroje_klientu_id\": 1,\n\"poznamka\": \"Poznámka\"\n}\n"
        };

        $.ajax(settings).done(function (response) {
            console.log(response);
        });
    });

});



//helpers
function formatCurrency(num) {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Kč";
}

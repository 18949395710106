/**
 * Created by janmencl on 07/02/2019.
 */

function List(items) {

    this.items = items || [];
    this.visibleItems = items;
    this.addedItems = [];


    this.addItem = function (index) {
        this.addedItems.push(this.items[index]);
        this.visibleItems = _.without(this.visibleItems, index);
    };

    this.removeItem = function (index) {
        this.visibleItems.push(this.addedItems[index]);
        this.addedItems = _.without(this.addedItems, index);
    };
}
/**
 * Created by janmencl on 07/02/2019.
 */


function Calculator(fixes, years) {

    this.propertyPrice = 0;
    this.loanAmount = 0;
    this.loanLength = 0; //id
    this.fix = 0; //id

    this.payment = 0;

    this.fixes = fixes || [];
    this.years = years || [];

    this.income = 0;
    this.expense = 0;

    this.validDTI = false;
    this.validDSTI = false;

    this.calculatePayment = function () {
        var n = this.years[this.loanLength] * 12;
        var q = 1 + (this.fixes[this.fix]/12);
        this.payment  = this.loanAmount * ((Math.pow(q,n) * (q-1)) / ((Math.pow(q,n))-1));
    };

    this.calculate = function () {
        this.calculateIncome();
        this.calculateExpense();
        if(this.income === 0){
            $("body").overhang({
                type: "error",
                html: true,
                closeConfirm: true,
                message: "Prosím zadejte příjmy a výdaje."
            });
            parent.postMessage({'task': 'scroll_top'}, parent_origin);
           /* $('html, body').animate({
                scrollTop: $(TabManager.currentTab).offset().top - 50
            }, 500);*/

            return false;
        }
        else{
            this.calculateDTI();
            this.calculateDSTI();
        }
        if(this.validDSTI && this.validDTI) {
            return true;
        }
    };

    this.changeValues = function () {

        var rV = null;
        this.propertyPrice = $("input[name='property_price']").val();


        if(this.propertyPrice >= ($("input[name='loan_amount']").val()*0.9)){
            this.loanAmount = $("input[name='loan_amount']").val();
        }

        if(this.loanAmount >= this.propertyPrice*0.9 ){
            this.loanAmount = this.propertyPrice*0.9;
            rV = false;
        }

        this.loanLength = $(".select-length").val();
        this.fix = $(".select-fix").val();

        this.calculatePayment();
        //console.log(this.payment);
        this.render();

        return rV;
    };

    this.render = function () {
        $("input[name='property_price']").val(this.propertyPrice);
        $(".property-price").val(this.formatCurrency(String(this.propertyPrice)));

        $("input[name='loan_amount']").val(this.loanAmount);
        $(".loan-amount").val(this.formatCurrency(String(this.loanAmount)));

        $('.payment').html(String(this.formatCurrency(this.payment.toFixed(0))));
        $('.additional-text').html("<span class='interest'>Při úroku <b>" + (this.fixes[this.fix]*100).toFixed(2) + "% ročně</b></span>");

        $('.propertyPrice').html(formatCurrency(String(this.propertyPrice)));
        $('.loanAmount').html(formatCurrency(String(this.loanAmount)));
        $('.fix').html(fixes_select[this.fix].text);
        $('.loanLength').html(years_select[this.loanLength].text);
        $('.monthlyPayment').html(formatCurrency(String(this.payment.toFixed(0))));
    };

    this.calculateDTI = function () {
        this.calculateIncome();
        this.calculateExpense();
        var yearIncome = (this.income * 12) - (this.expense *12);
        if(yearIncome * 9 < this.loanAmount){
            this.validDTI = false;
            $("body").overhang({
                type: "error",
                html: true,
                closeConfirm: true,
                message: "Poměr všech úvěrů k vašemu příjmu nově nesmí přesáhnout 9x vašeho příjmu. Prosím upravte příjem, nebo hodnotu hypotéky a pokračujte."
            });
            $('.income').each(function () {
                $(this).css('border-color', 'red');
            });
          /*  $('html, body').animate({
                scrollTop: $(TabManager.currentTab).offset().top - 50
            }, 500);*/
            parent.postMessage({'task': 'scroll_top'}, parent_origin);
            //parent.postMessage({'scroll_top': $(TabManager.currentTab).offset().top - 50}, 'https://vhd.vfd.cz');
        }
        else{
            this.validDTI = true;
        }

    };

    this.calculateDSTI = function () {
        this.calculateIncome();
        this.calculateExpense();

        if(((this.income - this.expense) * 0.45) < this.payment){
            this.validDSTI = false;
            /*$('html, body').animate({
                scrollTop: $(TabManager.currentTab).offset().top - 20
            }, 500);*/
            $('.income').each(function () {
                $(this).css('border-color', 'red');
            });
            $('.expense').each(function () {
                $(this).css('border-color', 'red');
            });
            $("body").overhang({
                type: "error",
                html: true,
                closeConfirm: true,
                message: "Poměr splátek všech úvěrů k vašemu měsíčnímu příjmu nově nesmí přesáhnout 45 % vašeho měsíčního příjmu. Prosím upravte příjem, nebo hodnotu hypotéky a pokračujte."
            });
            parent.postMessage({'task': 'scroll_top'}, parent_origin);
        }
        else{
            this.validDSTI = true;
        }



    };

    this.calculateIncome = function () {
        var income = 0;

        $('.income .input').each(function () {
            var input = $(this).parent().parent().find('.hidden-number');
            income += Number(input.val());
        });

        this.income = income;

    };

    this.calculateExpense = function () {
        var expenses = 0;


        $('.expense .input').each(function () {
            var input = $(this).parent().parent().find('.hidden-number');
            expenses += Number(input.val());
        });

        this.expense = expenses;
    };


    this.formatCurrency = function (num) {
        return num.replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " Kč";
    };


}






